/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { withBaseHref } from 'utils/getBaseHref';
import { ReactElement, ReactNode } from 'react';
import { useTenant } from 'tenancy/useTenant';

import { Link } from '../Link';

export function FileLink({ fileId, children }: { fileId: string; children: ReactNode }): ReactElement {
	const tenant = useTenant();
	const url = `/ui/files/${fileId}?tenantKey=${tenant.key}`;
	return (
		<Link href={withBaseHref(url)} variant={'primary'} display={'inline'}>
			{children}
		</Link>
	);
}
