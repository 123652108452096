/*
 * Copyright 2021 steadybit GmbH. All rights reserved.
 */

import { ExperimentPlayerTimeStamp } from 'components/ExperimentPlayer/types';
import ScrollContainer from 'components/ScrollContainer/ScrollContainer';
import LoadMoreIndicator from 'components/Pagination/LoadMoreIndicator';
import { ExecutionLogType } from 'services/executionLogsApi';
import { Container, Spinner, Stack, Text } from 'components';
import { useExecutionLogs } from 'services/useExecutionLogs';
import { ReactElement, useMemo } from 'react';
import { ExecutionLogEventVO } from 'ui-api';
import { theme } from 'styles.v2/theme';

import { ExperimentRunLogsAndMetricsProps } from '../experimentExecutionLogsAndMetrics';
import LoadingContent from './LoadingContent';
import EmptyContent from './EmptyContent';

function lastIndexBefore(log: ExecutionLogEventVO[], position: ExperimentPlayerTimeStamp | null): number | null {
	if (!position) {
		return null;
	}
	for (let i = 0; i < log.length; i++) {
		if (log[i].timestamp.getTime() > position) {
			return i - 1;
		}
	}
	return log.length - 1;
}

type EventLogProps = Omit<ExperimentRunLogsAndMetricsProps, 'selectedStepId' | 'setSelectedStepId'> & {
	type: ExecutionLogType | string;
	actionId: string | undefined;
	reason?: string;
	state: string;
	fragment: (idx: number, event: ExecutionLogEventVO, indexBeforePosition: number | null) => ReactElement;
};
export function EventLog({
	actionId,
	reason,
	state,
	experimentExecution,
	position,
	fragment,
	type,
	selectedLogLevel,
	setAvailableLogLevel,
}: EventLogProps): ReactElement {
	const liveUpdate = !experimentExecution.ended;
	const { result: logEvents, loadMore } = useExecutionLogs({
		id: actionId,
		executionId: experimentExecution.id,
		type,
		liveUpdate,
		selectedLogLevel,
		setAvailableLogLevel,
	});
	const indexBeforePosition = useMemo(() => lastIndexBefore(logEvents.value ?? [], position), [logEvents, position]);
	if (!logEvents.value?.length) {
		return (
			<Stack size={0} sx={{ alignItems: 'center', justifyContent: 'center', height: '100%' }}>
				{liveUpdate || logEvents.loading ? (
					<LoadingContent loading={logEvents.loading}>Waiting for events...</LoadingContent>
				) : state === 'FAILED' ? (
					<EmptyContent>
						<Text color={'coral'} muted>
							Failed to record Events: {reason}
						</Text>
					</EmptyContent>
				) : (
					<EmptyContent>No Events recorded.</EmptyContent>
				)}
			</Stack>
		);
	}

	return (
		<ScrollContainer height={283} heightPerChild={24}>
			{(fromChild, toChild) => (
				<>
					{indexBeforePosition === -1 && fromChild === 0 && (
						<Container height={24} sx={{ borderBottom: '2px solid ' + theme.colors.slateMid }} />
					)}
					{logEvents.value?.map((event, idx) => {
						if (idx < fromChild || idx > toChild) {
							return <Container key={idx} height={24} backgroundColor="white" />;
						}

						return fragment(idx, event, indexBeforePosition);
					})}
					{!loadMore && liveUpdate && (
						<Container display={'flex'} sx={{ height: 24, alignItems: 'center' }}>
							<Spinner variant={'medium'} color="neutral300" />
						</Container>
					)}
					{loadMore && <LoadMoreIndicator loadMore={loadMore} />}
				</>
			)}
		</ScrollContainer>
	);
}
