/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	IconComponent,
	IconContainer,
	IconContainerRegistry,
	IconDistribution,
	IconHelp,
	IconKubernetes,
} from 'components/icons';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { useLastVisitedView } from 'pages/landscape/hocs/lastVisitedView';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { Container, Heading, Stack, Text } from 'components';
import { Colors, Icon } from '@steadybit/ui-components-lib';
import { ReactElement, ReactNode, useState } from 'react';
import { localeCompareIgnoreCase } from 'utils/string';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { LandscapeViewVO } from 'ui-api';
import { theme } from 'styles.v2/theme';

import { useViewsUpdateSignal } from './hooks/useViewsUpdateSignal';
import DeleteViewModal from './modals/DeleteViewModal';
import TemplateViewFrame from './TemplateViewFrame';
import ViewFrame from './ViewFrame';
import { ampli } from '../../ampli';
import View from './View';

export default function CustomViews(): ReactElement {
	const team = useTeam();
	const reloadSignal = useViewsUpdateSignal();
	const response = usePromise<LandscapeViewVO[]>(() => Services.landscapeV2.getViews(team.id), [team.id, reloadSignal]);

	const { lastVisitedViewId, deleteLastViewedId } = useLastVisitedView();

	const templateViews = usePromise<LandscapeViewVO[]>(
		() => Services.landscapeV2.getTemplateViews(),
		[team.id, reloadSignal],
	);

	const { environments } = useEnvironments();

	const [viewToDelete, setViewToDelete] = useState<LandscapeViewVO | null>(null);

	const getTemplateIcon = (screenshot: string): IconComponent => {
		switch (screenshot) {
			case 'template_kubernetes':
				return IconKubernetes;
			case 'template_container_engine':
				return IconContainer;
			case 'template_kubernetes_zones':
				return IconDistribution;
			case 'template_container_image_registry':
				return IconContainerRegistry;
			default:
				return IconHelp;
		}
	};

	return (
		<Container
			as="main"
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',

				gridArea: 'main',
				height: '100%',
				width: '100%',
				overflowY: 'auto',
				pb: 'xLarge',
			}}
		>
			{viewToDelete && (
				<DeleteViewModal
					viewId={viewToDelete.id}
					viewName={viewToDelete.name}
					onClose={() => setViewToDelete(null)}
					onDeleteSuccess={() => {
						setViewToDelete(null);
						if (lastVisitedViewId === viewToDelete.id) {
							deleteLastViewedId();
						}
					}}
				/>
			)}
			<Section title="Saved views">
				<BlankViewFrame />

				{response.loading && <LoadingFrame />}
				{response.value
					?.sort((v1, v2) => localeCompareIgnoreCase(v1.name, v2.name))
					.map((view) => (
						<View
							key={view.id}
							view={view}
							onDelete={() => setViewToDelete(view)}
							environment={environments.find((e) => e.id === view.environmentId)}
						/>
					))}
			</Section>

			{templateViews.value && templateViews.value.length > 0 && (
				<Section title="Shared by Steadybit">
					{templateViews.value
						?.sort((view1, view2) => view1.name.localeCompare(view2.name))
						.map((view) => (
							<TemplateViewFrame
								key={view.id}
								templateId={view.id}
								title={view.name}
								description={view.description}
								icon={getTemplateIcon(view.screenshot)}
							/>
						))}
				</Section>
			)}
		</Container>
	);
}

interface SectionProps {
	children: ReactNode | ReactNode[];
	title: string;
}

function Section({ title, children }: SectionProps): ReactElement {
	return (
		<Stack size="large" mt="6rem" width="100%" maxWidth={864}>
			<Heading variant="xLarge">{title}</Heading>
			<Container
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr 1fr',
					gap: '24px',
				}}
			>
				{children}
			</Container>
		</Stack>
	);
}

function LoadingFrame(): ReactElement {
	return (
		<ViewFrame>
			<Container display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
				<LoadingIndicator variant="xxLarge" color="slate" />
			</Container>
		</ViewFrame>
	);
}

function BlankViewFrame(): ReactElement {
	return (
		<ViewFrame
			href="/landscape/explore/<new>"
			onClick={() => {
				ampli.landscapeExplorerNewView({
					landscape_explorer_new_view_context: 'saved_views',
					url: window.location.href,
				});
			}}
			sx={{
				bg: 'purple100',
				border: '1px solid ' + theme.colors.purple300,
			}}
		>
			<Stack size="large" alignItems="center" mt="80px">
				<Icon type="plus" size="xxxLarge" color={Colors.slate} />
				<Text variant="largeStrong" color="slate">
					Add new view
				</Text>
			</Stack>
		</ViewFrame>
	);
}
